<template>
  <div class="page">

    <div class="data-table-container">

      <div class="tools-bar">
        <el-button-group class="i">
          <!--          <el-button @click="() => onOpenDetail({})" type="primary">新建</el-button>-->
          <el-button @click="load">刷新</el-button>
        </el-button-group>

        <el-select class="i" v-model="filter_status" @change="() => load({page:1})" placeholder="请选择类型" clearable>
          <el-option label="待审核" value="待审核"/>
          <el-option label="正常" value="正常"/>
        </el-select>

        <div class="grow"></div>
      </div>

      <el-table stripe :data="tableData" v-loading="loading">
        <el-table-column prop="xid" width="110" label="XID">
          <template slot-scope="scope">
            <!-- v-if="scope.row.xid.includes('course')" -->
            <!-- <router-link  :to="`/course-editor?id=${scope.row.xid.slice(7)}`"> -->
            <router-link  v-if="scope.row.xid.includes('course')" :to="`/course-editor?id=${scope.row.xid.slice(7)}`">
              {{scope.row.xid}}
            </router-link>
            <router-link  v-else  :to="`/post-info?id=${scope.row.xid.slice(5)}`">
              {{scope.row.xid}}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="110"/>
        <el-table-column prop="content" label="内容"/>
        <el-table-column label="用户" width="200">
          <template slot-scope="scope">
            
            <xid-link xid="'user-' + scope.row.id" :name="'UID'+scope.row.id +' '+(scope.row.user.realname || scope.row.user.nickname)"/>
          </template>
        </el-table-column>

        <!-- <el-table-column label="" width="180">
          <template slot-scope="scope">
            <router-link v-if="scope.row.xid.includes('post')" :to="`/post-info?id=${scope.row.xid.slice(5)}`">链接
            </router-link>
          </template>
        </el-table-column> -->
        <el-table-column label="创建时间" width="160" prop="datetime_created"/>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="mini" type="danger"
                         v-if="scope.row.status === '待审核'" @click="() => onPass(scope.row.id)">通过审核
              </el-button>
              <el-button size="mini" @click="() => onRemove(scope.row.id)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination class="pagination"
                     layout="total, prev, pager, next"
                     @current-change="load"
                     :current-page.sync="page"
                     :page-size="30"
                     :total="total"/>

    </div>

  </div>
</template>

<script>
import XidLink from "@/comps/xid-link";

export default {
  components: {XidLink},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      dialogLoading: false,
      dialogForm: {
        user: {},
      },
      rules: {},
      inputSearch: '',
      typeSelect: '',
      typeOptions: ['类型1', '类型2'],
      tableData: [],
      page: 1,
      total: 1,
      filter_status: '待审核',
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.$api.request('/interact/list_comments_with_page', {
        page: this.page, filter_status: this.filter_status
      }).then(data => {
        this.tableData = data.contains
        this.page = data.page
        this.total = data.total
        this.loading = false
        window.scrollTo(0, 0)
      })
    },
    onPass(id) {
      this.$api.request('/interact/update_comment', {id, status: '正常'}).then(() => {
        this.$message.success('审核通过！')
        this.load()
      })
    },
    onCreate() {
    },
    onRemove(id) {
      if (confirm('您确定要删除吗？')) {
        this.$api.request('/interact/remove_comment', {id}).then(data => {
          this.$message.success('删除成功！')
          this.load()
        })
      }
    },
  }
}
</script>
